<template>
    <div class="journey-time">
        {{ prefix }} {{ timer }}
    </div>    
</template>
<script>
import {getMomentWithTimezoneOffset} from "@/utils/dateHelper"

export default {
    props: {
        appointment: Object
    },
    data() {
        return {
            prefix: '',
            timer: '-'
        }
    },
    mounted() {

    },
    methods: {
        setTimer() {
            this.prefix = this.appointment.journey_end ? 'Duração: ' : 'Espera: '
            const start = this.moment(this.appointment.journey_start)
            const end = this.appointment.journey_end ? this.moment(this.appointment.journey_end) : getMomentWithTimezoneOffset()
            const diff = end.diff(start, 'minutes')
            const hours = parseInt(diff/60)
            const minutes = parseInt(diff % 60)

            if (hours >= 24) {
                this.timer = 'maior que 1 dia'
                return 
            }

            this.timer = `${this.normalizeNumber(hours)}h${this.normalizeNumber(minutes)}min`
        },
        startTimer() {
            this.setTimer()
            return setInterval(() => this.setTimer(), 60000);
        },
        normalizeNumber(number) {
            return String(number).padStart(2, '0')
        }
    },
    watch: {
        appointment() {
            this.appointment?.journey_start && this.startTimer()
        }
    }
}
</script>
<style lang="scss" scoped>
.journey-time {
    display: inline-flex;
    color: var(--type-active);
}
</style>