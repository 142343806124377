import moment from 'moment'

/**
 * default server time difference in Minutes relative to UTC
 * @type {Number}
 */
export const TZ_DEFAULT_SERVER_TIME = -180
export const TZ_DEFAULT_TIME_UNIT = 'minutes'

export const parseTimestamp = (time) => {
  if (!time) return
  return moment(time).format('DD [de] MMMM [de] YYYY [às] HH[h]mm');
}

export const formatDate = (time) => {
  if (!time) return
  return moment(time).format('DD [de] MMMM [de] YYYY');
}

const getTimeDuration = (startTime, endTime) => {
  if (!startTime || !endTime) return
  return moment.duration(moment(endTime).diff(moment(startTime))).asHours()
}

export const timeDurationsFormated = (startTime, endTime) => {
  if (!startTime || !endTime) return
  const duration = getTimeDuration(startTime, endTime)
  const hours = Math.floor(duration)
  const minutes = Math.floor((duration - hours) * 60)
  if (!hours) return `${minutes} minutos`
  if (!minutes) return `${hours} horas`
  return `${hours} horas e ${minutes} minutos`
}

export const periodTimeFormated = (startTime, endTime) => {
  if (!startTime || !endTime) return
  const start = moment(startTime).format('HH[h]mm')
  const end = moment(endTime).format('HH[h]mm')
  return `Das ${start} às ${end}`
}

export const dateFormated = (date) => {
  if (!date) return
  return moment(date).format('dddd, DD [de] MMMM [de] YYYY');
}

export const parseDate = (date) => {
  if (!date) return
  return moment(date).format('DD/MM/YYYY');
}

export const parseDateToTime = (date) => {
  if (!date) return
  return moment(date).format('HH[h]mm');
}

export const isPastDate = (date) => {
  if (!date) return
  return moment(date).isBefore(moment().startOf('day'))
}

export const isFutureDate = (date) => {
  if (!date) return
  return moment(date).isAfter(moment().startOf('day'))
}

export const getAge = (date) => {
  if (!date) return
  return moment().diff(moment(date), 'years')
}

export const getFormatedTimeStamp = updatedAt => {
  if (!updatedAt) return
  return `às ${moment(updatedAt).format('H:mm')} do dia ${moment(
    updatedAt
  ).format('DD/MM/Y')}`
}

/**
 * Get a configured moment time according to the user timezone, if a server time is specified, calculates according to it
 * Use it -ONLY- when dealing with user client timezone calculations and conversions with server time, such as created_at, updated_at and so on
 * @returns {moment.Moment}
 */
export const getMomentWithTimezoneOffset = (serverTime) => {

  const timeObj = moment(serverTime);

  return serverTime ? timeObj.subtract(TZ_DEFAULT_SERVER_TIME, TZ_DEFAULT_TIME_UNIT)
                             .add((-1 * ((new Date).getTimezoneOffset())), TZ_DEFAULT_TIME_UNIT)
                    : timeObj;
}
